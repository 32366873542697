<template>

    <div class="page page-sidebar">
        <notifications/>
        <lego-side-bar class="text-white bg-primary" @link-event="linkEvent"
                       drop-down-animation="fade" :items="sideMenu"
                       logo-text="Pragati">
            <template #logo>
                <img src="../assets/img/logo/Xeo-Logo-White.png" @click="$router.push('/app/')" class="my-3 c-logo-img" alt="">
            </template>
        </lego-side-bar>
        <div class="page-content-outer">
            <div class="bg-2 nav-container bs-4">
                <lego-nav-bar logo-text="" @link-event="linkEvent" drop-down-animation="fade" hover-effect="0"
                              class="headerFixed py-2 m-0 nav container text-white bg-2" shadow="0" :items="topMenu"/>
            </div>
            <div class="page-content px-5 py-7">
                <div class="thin-scrollbar">
                    <transition name="fade"
                                enter-active-class="animated fadeIn"
                                leave-active-class="animated fadeOut" mode="out-in">
                        <router-view>

                        </router-view>
                    </transition>
                </div>
            </div>

            <div class="bg-primary-900 text-center p-3 text-2 fs--1">
                © {{ year }} Pragati. All Rights Reserved.
            </div>
        </div>
    </div>
</template>

<script>
import LegoSideBar from 'lego-framework/src/components/SideNavBar';
import LegoNavBar from 'lego-framework/src/components/NavBar';
import axios from 'secure-axios';
import urls from '../data/urls';
import getSideMenu from '../data/sideMenu';
import getTopMenu from '../data/topMenu';

export default {
    components: {
        LegoNavBar,
        LegoSideBar
    },
    data () {
        return {
            sideMenu: getSideMenu(),
            topMenu: getTopMenu(),
            value: '',
            year: new Date().getFullYear()
        };
    },
    methods: {
        async linkEvent (name) {
            if (name === 'logout') {
                localStorage.clear();
                await axios.get(urls.auth.logout);
                await this.$router.push({ path: '/login/' });
            }
        }
    },
    metaInfo: {
        titleTemplate: '%s - Pragati',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>
