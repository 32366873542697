import store from '../store';

const getSideMenu = function () {
    const user = store.getters.currentUser;

    const commonMenu = [];

    const adminMenu = [
        {
            icon: 'fa fa-list-alt',
            text: 'Interview Templates',
            link: '/admin/interview-templates/'
        },
        {
            icon: 'fa fa-list-alt',
            text: 'Attended Interviews',
            link: '/admin/attended-interviews/'
        }
    ];

    const userMenu = [
        {
            icon: 'fa fa-list',
            text: 'Interviews',
            link: '/user/interviews/'
        },
        {
            icon: 'fa fa-list',
            text: 'Attended Interviews',
            link: '/user/attended-interviews/'
        }
    ];

    if (user.is_superuser) {
        return [
            ...adminMenu, ...commonMenu
        ];
    }

    return [
        ...userMenu, ...commonMenu
    ];
};
export default getSideMenu;
