import store from '../store';

const getTopMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: 'fa fa-user',
            class: 'text-capitalize',
            text: user.name,
            items: [
                {
                    icon: 'fa fa-power-off',
                    text: 'Logout',
                    event: 'logout'
                }
            ]
        }
    ];

    return [
        ...menu
    ];
};
export default getTopMenu;
